import React from 'react'

import SEO from '../../components/seo'
import Header from '../../components/header'
import Sectors from '../../components/sectors/Sectors'

const SectorsPage = () => {
  return (
    <>
      <SEO path="/sectors" title="Sectors" />
      <Header />
      <Sectors />
    </>
  )
}

export default SectorsPage
