/* eslint-disable no-use-before-define */
import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Link from "../../utils/link"

import "./Sectors.scss"

class Sectors extends Component {
  state = {
    isMobile: false,
  }

  componentDidMount() {
    if (window.innerWidth < 900) {
      this.setState({ isMobile: true })
    }
  }
  mouseEnter = (i) => () => {
    this.refs[`video-${i}`].play()
  }

  mouseLeave = (i) => () => {
    this.refs[`video-${i}`].pause()
  }

  getVideo(el) {
    return typeof window !== "undefined" && window.innerWidth > 900
      ? el.node.acf.menuVideo
      : el.node.acf.menuVideoMobile
  }

  render() {
    const { data } = this.props
    const { isMobile } = this.state

    return (
      <section className="sectors">
        {data.allWpSector.edges.map((el, i) => (
          <Link
            key={i + Math.random()}
            to={`/sector/${el.node.slug}/`}
            className="sectors__sector"
            onMouseEnter={this.mouseEnter(i)}
            onMouseLeave={this.mouseLeave(i)}
            style={{
              backgroundImage: `url(${
                isMobile &&
                el?.node?.acf?.menuImageMobile?.localFile?.childImageSharp
                  ?.original?.src
                  ? el.node.acf.menuImageMobile.localFile.childImageSharp
                      .original.src
                  : el.node.acf.menuImage.localFile.childImageSharp.original.src
              })`,
            }}
          >
            <video
              ref={`video-${i}`}
              src={this.getVideo(el)}
              playsInline
              muted
              loop
            />
            <div className="sectors__header" />
            <h3 className="sectors__title">
              <span>{el.node.title}</span>
            </h3>
            <p className="sectors__text">{el.node.acf.description}</p>
          </Link>
        ))}
      </section>
    )
  }
}

Sectors.propTypes = {
  data: PropTypes.object,
}

Sectors.defaultProps = {
  data: null,
}

export default function SectorsWrap(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpSector(sort: { fields: menuOrder }) {
            edges {
              node {
                slug
                title
                acf {
                  description
                  menuVideo
                  menuVideoMobile
                  menuImage {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                  menuImageMobile {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Sectors data={data} {...props} />}
    />
  )
}
